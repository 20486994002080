import React from 'react'
import './Footer.css'
import Insta from '@iconscout/react-unicons/icons/uil-instagram'
import Facebook from '@iconscout/react-unicons/icons/uil-facebook'
import Youtube from '@iconscout/react-unicons/icons/uil-youtube'
import Line from '@iconscout/react-unicons/icons/uil-line'

import Wave from '../../img/wave.png'

const Footer = () => {
    return (
        <div className="footer">
            <img src={Wave} alt="" style={{ width: '100%' }} />
            <div className="f-content">
                <span>skylinehu27@gmail.com</span>
                <div className="f-icons">
                    <a href='https://www.instagram.com/huweichuan/'>
                        <Insta color='white' size='3rem' />
                    </a>
                    <a href='https://www.facebook.com/CubsHuDesign'>
                        <Facebook color='white' size='3rem' />
                    </a>
                    <a href='https://www.youtube.com/@Cubshu?sub_confirmation=1'>
                        <Youtube color='white' size='3rem' />
                    </a>
                    <a href='https://www.cubshu.com/shortlink/line'>
                        <Line color='white' size='3rem' />
                    </a>
                </div>
                <span>Copyright © 2023 - 胡筱熊 Cubshu. All rights reserved.</span>

            </div>
        </div>
    )
}

export default Footer