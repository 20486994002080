import React from 'react'
import './Portfolio.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import MavicMini from '../../img/MavicMini.png'
import Scaled from '../../img/移動星球-scaled.png'
import Wendaorailway from '../../img/Wendao-railway.png'
import HLC from '../../img/HomeLoanCalculation.png'
import LineSticker from '../../img/sticker.png'
import 'swiper/css'
import { themeContext } from "../../Context";
import { useContext } from "react";

const Portfolio = () => {

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
        <div className="portfolio" id='Portfolio'>
            {/* heading */}
            <span style={{ color: darkMode ? 'white' : '' }}>各類作品參考</span>
            <span>作品展示</span>

            {/* slider */}
            <Swiper
                // loop={true}
                spaceBetween={30}
                slidesPerView={3}
                grabCursor={true}
                className='portfolio-slider'
            >
                <SwiperSlide>
                    <a href="https://www.youtube.com/watch?v=j3GrILjty0g">
                        <img src={MavicMini} alt="MavicMini" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://store.line.me/stickershop/author/61227/zh-Hant">
                        <img src={LineSticker} alt="CubshuLineSticker" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.cubshu.com/wendao-railway/">
                        <img src={Wendaorailway} alt="Wendao-railway" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="https://www.cubshu.com/games/moving_planet_H5/moving_planet.php">
                        <img src={Scaled} alt="moving_planet" />
                    </a>
                </SwiperSlide>

                <SwiperSlide>
                    <a href="https://www.cubshu.com/tool/HomeLoanCalculation/">
                        <img src={HLC} alt="HomeLoanCalculation" />
                    </a>
                </SwiperSlide>

            </Swiper>
        </div>
    )
}

export default Portfolio