import React from 'react'
import './Works.css'
import Youtube from '../../img/work-05.png'
import Line from '../../img/work-06.png'
import CubsHu from '../../img/work-08.png'
import Facebook from '../../img/work-04.png'
import Instagram from '../../img/work-07.png'
import { themeContext } from "../../Context";
import { useContext } from "react";
import { motion } from 'framer-motion';


const Work = () => {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="works">
      <div className="awesome">
        <span style={{ color: darkMode ? 'white' : '' }}>多才多藝的個人開發者</span>
        <span>各種服務 & 多個領域</span>
        <span>
          幫助您實現您的數位項目和夢想。無論您是需要建立一個網站、改進您的內容策略，
          <br />
          還是創建引人入勝的視頻，筱熊都能夠滿足您的需求。
        </span>
        <a href="https://www.cubshu.com/"><button className='button s-button'>參考我的作品</button></a>
        <div className='blur s-blurl' style={{ background: '#c1f5ff' }}></div>
      </div>

      {/* right side */}
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}

          className="w-mainCircle">
          <a href="https://www.youtube.com/@Cubshu?sub_confirmation=1">
            <div className="w-secCircle">
              <img src={Youtube} alt="" />
            </div>
          </a>
          <a href="https://www.cubshu.com/shortlink/line">
            <div className="w-secCircle">
              <img src={Line} alt="" />
            </div>
          </a>
          <a href="https://www.cubshu.com/">
            <div className="w-secCircle">
              <img src={CubsHu} alt="" />
            </div>
          </a>
          <a href="https://www.facebook.com/CubsHuDesign">
            <div className="w-secCircle">
              <img src={Facebook} alt="" />
            </div>
          </a>
          <a href="https://www.instagram.com/huweichuan/">
            <div className="w-secCircle">
              <img src={Instagram} alt="" />
            </div>
          </a>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>
    </div>
  )
}

export default Work