import React from 'react'
import './Intro.css'
import Youtube from '../../img/youtube_icon.png'
import Instagram from '../../img/instagram.png'
import Facebook from '../../img/facebook_icon.png'
import Vector1 from '../../img/Vector_cubshu-09.png'
import Vector2 from '../../img/Vector_cubshu-10.png'
import boy from '../../img/CubsHuDesign4.png'
import thumbup from '../../img/SEO.png'
import Crown from '../../img/design.png'
import glassesimoji from '../../img/glassesimoji.png'
import FloatingDiv from '../FloatingDiv/FloatingDiv'
import { themeContext } from "../../Context";
import { useContext } from "react";
import { motion } from 'framer-motion';

const Intro = () => {

    const transition = { duration: 2, type: 'spring' }

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
        <div className="intro">
            <div className="i-left">
                <div className="i-name">
                    <span style={{ color: darkMode ? 'white' : '' }}>架設網站？</span>
                    <span>來找 筱熊 為你服務吧！</span>
                    <span>筱熊是一位多才多藝的個人工作室，擅長提供各種服務。</span>
                    <span>包括網頁設計、網站架設、SEO文章撰寫、圖像設計、平面設計、影片剪輯及製作等多個領域。</span>
                </div>

                <a href='https://www.facebook.com/CubsHuDesign'><button className="button i-button">手刀找筱熊</button></a>
                <div className="i-icons">
                    <a href='https://www.youtube.com/@Cubshu?sub_confirmation=1'>
                        <img src={Youtube} alt="CubsHuFacebook" />
                    </a>
                    <a href="https://www.facebook.com/CubsHuDesign">
                        <img src={Facebook} alt="CubsHuInstagram" />
                    </a>
                    <a href='https://www.instagram.com/huweichuan/'>
                        <img src={Instagram} alt="CubsHuInstagram" />
                    </a>
                </div>
            </div>
            <div className="i-right">
                <img src={Vector1} alt="" />
                <img src={Vector2} alt="" />
                <img src={boy} alt="" />
                <motion.img
                    initial={{ left: '-36%' }}
                    whileInView={{ left: '-24%' }}
                    transition={transition}
                    src={glassesimoji} alt="" />
                <motion.div

                    initial={{ top: '-4%', left: '74%' }}
                    whileInView={{ left: '68%' }}
                    transition={transition}

                    style={{ top: '-4%', left: '68%' }}
                    className='floating-div'
                >
                    <FloatingDiv image={Crown} txt1='網站' txt2='設計、架設' />
                </motion.div>

                <motion.div
                    initial={{ top: '18rem', left: '9rem' }}
                    whileInView={{ left: '0rem' }}
                    transition={transition}

                    style={{ top: '18rem', left: '0rem' }}
                    className='floating-div'
                >
                    <FloatingDiv image={thumbup} txt1='SEO' txt2='文案撰寫、網站優化' />
                </motion.div>
                {/* {blur divs} */}
                <div className='blur' style={{ background: 'rgb(238 210 255)' }}></div>
                <div className='blur' style={{ background: '#c1f5ff', top: '17rem', width: '21rem', height: '11rem', left: '-9rem' }}></div>
            </div>
        </div>

    )
}

export default Intro

