import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { themeContext } from "../../Context";
import { useContext } from "react";

// Name: Cubs_leading_Gmail
// service ID: service_su7lyvu
// Template ID: template_7edwdhl
const Contact = () => {

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    const form = useRef();

    const [done, setDone] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_su7lyvu', 'template_7edwdhl', form.current, 'L9ClDKoyaj9Uft52s')
            .then((result) => {
                console.log(result.text);
                setDone(true);
            }, (error) => {
                console.log(error.text);
            });
    };



    return (
        <div className="contact-form" id='Contact'>
            <div className="w-left">
                <div className="awesome">
                    <span style={{ color: darkMode ? 'white' : '' }}>您有上述相關的問題</span>
                    <span>歡迎與我聯繫</span>
                    <div className="blur s-blur1" style={{ background: '#abf1ff94' }}></div>
                </div>
            </div>

            <div className="c-right">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='user_name' className='user' placeholder='Name/名字' />
                    <input type="text" name='user_email' className='user' placeholder='Email/電子郵件' />
                    <textarea name="message" className='user' placeholder='Message/留下您的問題'></textarea>
                    <input type="submit" value='Send' className='button' />
                    <span>{done && '感謝您的聯絡，訊息已送出，等候筱熊聯絡！'}</span>
                    <div className='blur c-blur1' style={{ background: 'var(--purple)' }}></div>
                </form>
            </div>

        </div>
    )
}

export default Contact