import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'

import { themeContext } from "../../Context";
import { useContext } from "react";

import { motion } from 'framer-motion';

const Services = () => {

    const transition = { duration: 1, type: 'spring' }

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
        <div className="services" id='Services'>
            {/* left side */}
            <div className="awesome">
                <span style={{ color: darkMode ? 'white' : '' }}>胡筱熊 CubsHu</span>
                <span>創意無限，專業非凡。</span>
                <span>
                    網頁設計、SEO文章撰寫、圖像設計、平面設計還是影片剪輯，
                    <br />
                    筱熊以其多才多藝聞名。一同探索筱熊多采多姿的數位世界。
                </span>
                <a href='https://www.cubshu.com/'><button className='button s-button'>參考我的作品</button></a>
                <div className='blur s-blurl' style={{ background: '#c1f5ff' }}></div>
            </div>
            {/* right side */}
            <div className="cards">
                <motion.div
                    initial={{ left: '14rem' }}
                    whileInView={{ left: '25rem' }}
                    transition={transition}

                    style={{ left: '14rem' }}>
                    <Card
                        emoji={HeartEmoji}
                        heading={'設計'}
                        detail={"Photoshop, Illustrator, AutoCAD, SketchUp"}

                    />
                </motion.div>
                {/* second card */}
                <motion.div
                    initial={{ left: "-11rem", top: "12rem" }}
                    whileInView={{ left: "-4rem" }}
                    transition={transition}

                    style={{ top: '12rem', left: '-4rem' }}>
                    <Card
                        emoji={Glasses}
                        heading={'網站'}
                        detail={"Html, Css, javascript, Wordpress, Vue, React, Flutter, web API"}
                    />
                </motion.div>
                {/* 3rd card */}
                <motion.div
                    initial={{ top: "19rem", left: "25rem" }}
                    whileInView={{ left: "12rem" }}
                    transition={transition}
                    style={{ top: '19rem', left: '12rem' }}>
                    <Card
                        emoji={Humble}
                        heading={'SEO'}
                        detail={"文章撰寫, 網站優化, 關鍵字優化, GA4, GTM, 數據分析"}
                    />
                </motion.div>
                <div className='blur s-blur2' style={{ background: 'var(--purple)' }}>

                </div>

            </div>
        </div>
    )
}

export default Services